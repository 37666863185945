<template>
  <v-simple-table dense>
    <template v-slot:default>
      <thead>
        <tr>
          <th>User</th>
          <th>File</th>
          <th>Status</th>
          <th>Total lines</th>
          <th>Processed lines</th>
          <th>Inserted count</th>
          <th>Modified count</th>
          <th>Matched count</th>
          <th>Created at</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in data" :key="index">
          <td>
            {{ item.user ? item.user.username : "No user" }}
          </td>
          <td>
            <a :href="item.file">{{
              item.file.length > 25 ? `${item.file.slice(0, 30)}...` : item.file
            }}</a>
          </td>
          <td>
            <v-tooltip bottom v-if="item.status_comment">
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  v-bind="attrs"
                  v-on="on"
                  x-small
                  :color="getImportedFileStatusColor(item.status)"
                  class="mr-1"
                >
                  {{ IMPORED_FILE_STATUS[item.status] }}
                </v-chip>
              </template>
              <span>{{ item.status_comment }}</span>
            </v-tooltip>
            <v-chip
              v-else
              x-small
              :color="getImportedFileStatusColor(item.status)"
              class="mr-1"
            >
              {{ IMPORED_FILE_STATUS[item.status] }}
            </v-chip>
          </td>
          <td>
            {{ item.total_lines }}
          </td>
          <td>
            {{ item.processed_lines }}
          </td>
          <td>
            {{
              item.processed_data && item.processed_data.upserted_count
                ? item.processed_data.upserted_count
                : "0"
            }}
          </td>
          <td>
            {{
              item.processed_data && item.processed_data.modified_count
                ? item.processed_data.modified_count
                : "0"
            }}
          </td>
          <td>
            {{
              item.processed_data && item.processed_data.matched_count
                ? item.processed_data.matched_count
                : "0"
            }}
          </td>
          <td>
            {{ formatDate(item.created_at) }}
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import {
  renderDateTime,
  IMPORED_FILE_STATUS,
  getImportedFileStatusColor,
} from "@/services/services";

export default {
  props: ["data"],
  methods: {
    formatDate(date) {
      return renderDateTime(date);
    },
  },
  computed: {
    getImportedFileStatusColor: () => getImportedFileStatusColor,
    IMPORED_FILE_STATUS: () => IMPORED_FILE_STATUS,
  },
};
</script>
